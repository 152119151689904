'use strict';

function guessEnvironment() {
  var assetModifier;
  const serverEnv = serverEnvironment();

  if (serverEnv) {
    return serverEnv;
  }

  // Otherwise, fall back to implied environment name
  const domain = document.location.href.split('/')[2].replace(/\./g, '-');
  const build = buildType();

  if (build === 'unknown') {
    assetModifier = 'unknown-assets';
  } else if (build === 'production') {
    assetModifier = 'prod';
  } else {
    assetModifier = 'dev-assets';
  }
  return [domain, assetModifier].join('#');
}

function buildType() {
  const buildElement = document.querySelector('meta[name=build]');

  if (!buildElement) {
    return 'unknown';
  } else {
    return buildElement.content;
  }
}

function serverEnvironment() {
  const explicitEnvironment = document.querySelector('meta[name=environment]');

  // If the template tells us the environment name
  if (explicitEnvironment && explicitEnvironment.content) {
    return explicitEnvironment.content;
  }
}

function serverDeployTime() {
  const deployElement = document.querySelector('meta[name=deployed_at]');

  // If the template tells us the environment name
  if (deployElement) {
    return deployElement.content || 0;
  } else {
    return 0;
  }
}

function serverSha() {
  const shaElement = document.querySelector('meta[name=sha]');

  // If the template tells us the environment name
  if (shaElement) {
    return shaElement.content || 'Unknown';
  } else {
    return 'Unknown';
  }
}

function serverBranch() {
  const branchElement = document.querySelector('meta[name=branch]');

  // If the template tells us the environment name
  if (branchElement) {
    return branchElement.content || 'Unknown';
  } else {
    return 'Unknown';
  }
}

module.exports = {
  version: process.env.RELEASE || 'UNDEFINED',
  jsSha: process.env.GIT_SHA || 'UNDEFINED',
  build: buildType(),
  environment: guessEnvironment(),
  serverDeployTime: serverDeployTime(),
  serverSha: serverSha(),
  serverBranch: serverBranch()
};
