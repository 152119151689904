'use strict';

const events = require('../core/events');
const forgeUiComponent = require('../framework/ui-component');
const BroadcastMetadata = require('../core/broadcastmetadata');
const StaticPlaybackMetadata = require('../core/static-playback-metadata');

/* Attaches globally to the page and captures PageAudio declared in content, emitting events for UI */
var PageAudioPlayer = forgeUiComponent('PageAudioPlayer', {}, function PageAudioPlayerConstructor(el, options, builder) {
  var self = this;
  var didRecentlyStop = false;

  builder.defineClassState('loading', 'is-page-audio-loading');
  builder.defineClassState('playing', 'is-page-audio-playing', null, function afterSet(newValue) {
    /* To ensure we ignore delayed progress events, catch if playback ceases */
    if (newValue === false) {
      didRecentlyStop = true;
      setTimeout(function () {
        didRecentlyStop = false;
      }, 1000);
    }
  });

  Object.defineProperty(this, 'src', {
    get: function getSrc() {
      // Support base64 encoded src by default
      var src = this.queryPropertyAttribute('content', 'data-audio-src');
      if (src) {
        try {
          src = atob(src);
        } catch (e) {
          // Failed to decode base64 url
        }
        return src;
      }
      return null;
    }
  });

  Object.defineProperty(this, 'broadcast', {
    get: function getTrackId() {
      return this.queryPropertyAttribute('content', 'data-audio-broadcast-id');
    }
  });

  Object.defineProperty(this, 'caption', {
    get: function getPlayerCaption() {
      return this.queryPropertyAttribute('content', 'data-audio-caption');
    }
  });

  Object.defineProperty(this, 'title', {
    get: function getPlayerCaption() {
      return this.queryPropertyAttribute('content', 'data-audio-title');
    }
  });

  Object.defineProperty(this, 'show', {
    get: function getPlayerShowName() {
      return this.queryPropertyAttribute('content', 'data-audio-show');
    }
  });

  Object.defineProperty(this, 'showUrl', {
    get: function getPlayerShowUrl() {
      return this.queryPropertyAttribute('content', 'data-audio-showUrl');
    }
  });

  Object.defineProperty(this, 'image', {
    get: function getPlayerMediaImage() {
      return this.queryPropertyAttribute('content', 'data-audio-image');
    }
  });

  this.queryProperty('playButton').addEventListener('click', function (e) {
    e.preventDefault();
    self.handlePlayButtonClick();
  });

  // Listen to global audio events to update the UI
  document.addEventListener(events.audioSourceLoading, function handleLoading(e) {
    self.playing = false;
    self.loading = self.eventMatchesPlayer(e);
  });

  document.addEventListener(events.audioSourcePlaying, function handlePlaying(e) {
    self.loading = false;
    self.playing = self.eventMatchesPlayer(e);
  });

  /* By subscribing to TimeUpdate as well as playing, we will update state on
   * content loaded after audio is already playing */
  document.addEventListener(events.audioSourceTimeUpdate, function handlePlaying(e) {
    /* An update event can still be triggered after the pause/end event so we track it */
    if (didRecentlyStop) {
      return;
    }
    self.loading = false;
    self.playing = self.eventMatchesPlayer(e);
  });

  document.addEventListener(events.audioSourceResumed, function handleResume(e) {
    self.playing = self.eventMatchesPlayer(e);
    self.loading = false;
  });

  document.addEventListener(events.audioSourcePaused, function handlePause() {
    self.playing = false;
    self.loading = false;
  });

  document.addEventListener(events.audioSourceEnded, function handleEnded() {
    self.playing = false;
    self.loading = false;
  });

  // Listen to page navigation to monitor for changes in the page audio context
  window.addEventListener('load', function () {
    self.handleNavigationChange();
  });

  document.addEventListener(events.navigationRequestEnded, function () {
    self.handleNavigationChange();
  });

  document.addEventListener(events.uiWantsPageAudioMetadata, function () {
    // Restate navigation if requested
    self.handleNavigationChange(true);
  });
});

PageAudioPlayer.prototype.emitNewAudioDiscovery = function emitPageAudioEvent() {
  const pageUpdate = {
    src: this.src,
    metadata: {
      title: this.title,
      artist: this.caption,
      image: this.image,
      isStream: false,
      broadcastId: this.broadcast
    }
  };

  this.dispatchEvent(events.pageAudioMetadataUpdate, pageUpdate);
};

PageAudioPlayer.prototype.handleNavigationChange = function handleNavigation(forceRestatement) {
  if (!forceRestatement && this.broadcast === this.lastBroadcastId) {
    // Same audio, so chill out
    return;
  }
  this.lastBroadcastId = this.broadcast;

  // No audio, so send the all-clear
  if (!this.lastBroadcastId) {
    this.dispatchEvent(events.pageAudioMetadataCleared);
    return;
  }

  // New audio, so emit discovery
  this.emitNewAudioDiscovery();
};

PageAudioPlayer.prototype.handlePlayButtonClick = function handleClick() {
  if (this.playing) {
    document.dispatchEvent(new CustomEvent(events.audioWantsPause));
    return;
  }

  const playbackEventPayload = {
    url: this.src,
    metadata: {} // TODO: Get core/audio out of the metadata business
  };

  document.dispatchEvent(new CustomEvent(events.audioWantsPlayback, {
    detail: playbackEventPayload
  }));

  if (!this.metadataHandler && this.broadcast) {
    this.metadataHandler = new BroadcastMetadata(this.src, this.broadcast);
  }

  if (!this.metadataHandler && this.caption) {
    this.metadataHandler = new StaticPlaybackMetadata(this.src, {
      title: this.caption,
      artist: this.artist,
      show: this.show,
      showUrl: this.showUrl,
      image: this.image
    });
  }
};

PageAudioPlayer.prototype.eventMatchesPlayer = function eventMatchesPlayer(event) {
  return event.detail && event.detail.src === this.src;
};

module.exports = PageAudioPlayer;
